@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=K2D&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Limelight&family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');



.card {
  position: relative;
  width: 300px;
  height: 350px;
  margin: 0.6rem;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px transparent;
  background-clip: padding-box;
  box-shadow: 0px 10px 10px rgba(46, 54, 68, 0.03);
}



.container .card .content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.5;
  transition: 0.5s;
}

 .container .card .content .img {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  border: 10px double rgba(0, 0, 0, 0.25);
}

.container .card .content .img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.container .card .content .cardContent h3 {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin: 20px 0 10px;
  line-height: 1.1em;
}

.container .card .content .cardContent h3 #position {
  padding-top: 2rem;
  font-size: 15px;
  font-weight: 300;
  text-transform: initial;
}

.container .card .content .cardContent h3 #work {
  font-size: 12px;
  font-weight: 300;
  text-transform: initial;
}

.container .card .sci {
  position: absolute;
  bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.container .card .sci li {
  margin: 0 10px;
  transform: translateY(40px);
  opacity: 0;
  transition: 0.5s;
  transition-delay: calc(0.02s);
}
.container .card .sci li a {
  font-size: 24px;
}
.container .card .sci #work {
  display: flex;
  margin-left: -110px;
  transform: translateY(50px);
  opacity: 0;

  
}
.container .card:hover .content {
  opacity: 1;
  transform: translateY(-30px);

  
}

.container .card:hover .sci li {
  transform: translateY(0px);
  opacity: 1;
}

.container .card .content .cardContent h3 #work {
  font-size: 12px;
  font-weight: 300;
  text-transform: initial;
}

.container .card .sci #work {
  display: flex;
  margin-left: -110px;
  transform: translateY(100px);
  opacity: 0;
  transition: 0.5s;
  transition-delay: 0.4s;
}
.container.card:hover .sci #work {
  transform: translateY(100px);
  opacity: 0.5;
}

