@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=K2D&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Limelight&family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');


#cards{

  color:aliceblue;
  border: 10px double rgba(0, 0, 0, 0.25);
  transition-duration: 250ms;

}

#cards:hover{
-ms-flex:1;
  flex:1;
  transform: translate3d(0, -5px,0);
  transition-duration: 250ms;
  box-shadow: 5px 10px #000000;
  border-bottom:0px;
  border-color:#F27678;


}
#groupImg{
  border: 10px double rgba(0, 0, 0, 0.25);
}
#groupImg:hover{
  transition-duration: 250ms;
  box-shadow: 5px 10px #151515;
  border-color:#F27678;
}

#tdlogo{
  border: 10px double rgba(0, 0, 0, 0.25);
}
#tdlogo:hover{
  transition-duration: 250ms;
  box-shadow: 5px 10px #151515;
  border-color:#FFFEFC;

}

#ProjectCards:hover{
  transition-duration: 250ms;
  box-shadow: 5px 10px #151515;
}

#up:hover{
  fill: #BD5868;
}

svg{
  stroke: #151515;
}

svg:hover{
  cursor: pointer;
 
}


#carousel{
  text-align: center;
  align-items: center;

}

#title_hero_mct{
  font-size: 5vw;
  margin: 0;
  padding-bottom: 0.7rem;
  letter-spacing: 0.5rem;
  transition: all 0.3s ease;
  z-index: 3;
  text-decoration: solid;
  cursor:pointer;
  color:#26FFDF;
  animation-iteration-count:1;
  
}

#title_hero_ie{
  font-size: 5vw;
  margin: 0;
  padding-bottom: 0.7rem;
  letter-spacing: 0.5rem;
  transition: all 0.3s ease;
  z-index: 3;
  text-decoration: solid;
  cursor:pointer;
  color:#BD5868;
  animation-iteration-count:1;
  
}

@media (prefers-reduced-motion: no-preference) {
  #title_hero_ie {
    animation: text-colour-1 ease-in-out  3s;
  }
  #title_hero_mct {
    animation: text-colour-2 ease-in-out  3s;
  }
}

@keyframes text-colour-1 {
  0% {
    color:#011c26
  }

  
  50% {
   color:#FFFEFC
  }

  100% {
   color:#BD5868
  }
}

@keyframes text-colour-2 {
  0% {
   color:#011c26
  }
 
  50% {
   color:#FFFEFC
  }

  100% {
  color:#26FFDF
  }
}

@keyframes star-opacity{
0%{
    opacity:0.2;
  }
  50%{
      opacity: 0.9;
  }
  100%{
    opacity:0.2;
    }
}
/* #title_hero:hover {
  color: #BD5868;
} */

#heroStars{
  animation: star-opacity infinite 3s ease-in-out;
}
.sub_hero{
  font-size: 2vw;
  letter-spacing: 0.7em;
  font-family: "ROBOTO", sans-serif;
  font-weight: 300;
  color: aliceblue;
  z-index: 4;
  
}

@media (prefers-reduced-motion: no-preference) {
  #fingerprint {
    animation: App-logo-linear infinite 3s ease-in-out;
  }
}




@keyframes App-logo-linear {
  0% {
    opacity:1;
  }
  50% {
    opacity:0.2;
  }
  100% {
    opacity:1;
  }
}

@keyframes frame-color {
  0% {
   border-color:rgba(0, 0, 0, 0.25)
  }
 
  50% {
   border-color:#F27678
  }

  100% {
  border-color:rgba(0, 0, 0, 0.25)
  }
}

  .img {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  border: 10px double rgba(0, 0, 0, 0.25);
 
}
.img{
  animation: frame-color infinite 3s ease-in-out;
}
  